module.exports = {
    enterNumber: {
        secondTitle: "Welcome",
        tips: "Please input your mobile number to receive a one time verification passcode",
        phonePlaceHolder: "Your phone number",
        phoneRuleMessage: "not correct",
        smallTips: "Your number will remain private",
        getOTP: "Get OTP",
        toastSuccess: "Successful",
        toastFail: "Fail",
    },
    enterOTP: {
        secondTitle: "Enter your OTP",
        tips: "Please enter the five digit one time verification passcode sent via text to your registered mobile",
        resendOTP: "Resend OTP",
        invalidOTP: "Invalid OTP",
        OTPRuleMessage: "The OTP you entered was invalid, please re-enter and try again",
        verify: "Verify",
        ok: "Ok",
    },
    registration: {
        ageGroup: 'kumpulan umur',
        otherBrands: 'Estée Lauder Companies',
        frontierNewSub1: "Ya! Saya ingin menerima komunikasi promosi dari jenama {0}, termasuk {1}.",
        frontierNewSub2: "Saya telah membaca dan bersetuju dengan {0} {1}. Saya berminat untuk menyertai Program Kesetiaan {2} dan saya mengesahkan bahawa saya berumur dewasa atau lebih dan telah membaca, memahami dan bersetuju dengan {3}.",
        loyalty: 'Terma & Syarat Program Kesetiaan {0}',
        malay: 'Melayu',
        chinese: 'Cina',
        indian: 'India',
        other: 'Lain-lain',
        counter: 'Kaunter / Kedai',
        secondTitle: "Multiple Membership Found!",
        noUserSecondTitle: "One More Step",
        tips: "Please complete the form below to confirm your membership details and ensure your loyalty points are awarded for your transactions.",
        formValueTitle: "Gelaran",
        formValueFirstName: "Nama Pertama",
        formValueLastName: "Nama Keluarga",
        formValueDateOfBirth: "Tarikh Lahir",
        formValueDay: "Hari",
        formValueMonth: "Bulan",
        formValueYear: "Tahun",
        formValueMobileNumber: 'No. Telefon Bimbit Contoh 12-1234 567',
        formValueMobileNumberoth: 'No. Telefon Lain Contoh 3-1234 5678',
        formValueMobileEmail: "Alamat Emel",
        formValueMobileGender: "Jantina",
        formValueMobileGenderFemale: "Perempuan",
        formValueMobileGenderMale: "Lelaki",
        secondTips1: "I would like receive news, offers and other marketing communications form, and be contacted for surveys by ",
        secondTips2: " and their products as listed in the Pricacy Notice overleaf via:",
        subscribeAll: "Semua saluran di bawah",
        marketiong: "Atau saluran yang dipilih secara individu",
        marketiongEmail: "Emel",
        marketiongText: "SMS",
        marketiongPhone: "Panggilan Telefon",
        marketiongPost: "Surat-menyurat",
        confirm1: "By signing below, I confirm I am 18 years old or above, I wish to register my information with ",
        brandName: "Jo MALONE",
        confirm2: ", that i have read , understood and agreed to be bound by the relevant terms and conditions and the Privacy Notice as set out overleaf regarding how my personal data may be used.",
        smallTips1: "I have read and agree to the ",
        smallTips2: "Privacy Policy ",
        smallTips3: " and ",
        termsAndConditions: "Terms and Conditions.",
        smallTips5: "the membership program's ",
        submit: "Submit",
        continue: "Meneruskan dengan pendaftaran",
        oK: "OK",
        cancle: "Batal",
        confirm: 'sahkan',
        statementUnderGender1: "Saya telah membaca dan bersetuju dengan Dasar Privasi ",
        statementUnderGenderNew: "Saya telah membaca dan bersetuju dengan {0} {1}.",
        possessive: ". (",
        possessive1: "(Loyalty T&C)",
        privacyPolicy: "Dasar Privasi",
        period: ")",
        period1: "(",
        statementSubscribe1: "I agree to receive news, offers and other marketing communications from, and be contacted for surveys by, ",
        statementSubscribe2: "Cosmetics Pte. Ltd. (operating entity of ",
        statementSubscribe3: "about any brands owned by its parent company, the Estée Lauder Companies Inc., including any brands acquired or launched in the future, and their products of the same kinds as listed in the ",
        statementSubscribe4: "via:",
        statementButton1: "Saya berminat untuk menyertai program kesetiaan dan dengan menanda kotak ini saya mengesahkan bahawa saya telah membaca, memahami dan bersetuju dengan Terma dan Syarat program kesetiaan  ",
        statementButton2: " and by ticking this box I confirm that I am of legal adult age or above and I have read, understood and agree to",
        loyaltyProgram: ".",
        formValueAge: 'Kumpulan Umur',
        formValueRace: 'Bangsa',
        formValueAddress: 'Alamat 1',
        formValueAddress2: 'Alamat 2',
        formValueCity: 'Bandar',
        formValueState: 'Sila pilih negeri.',
        formValuePostCode: 'Poskod',
        form19: 'Saya ingin menerima sebarang berita, tawaran dan kolateral pemasaran serta dihubungi untuk sebarang kaji selidik yang dikendalikan oleh Estee Lauder Malaysia Sdn. Bhd. (entiti sekutu ',
        form190: ' ), mengenai: ',
        form191: ' dan produk-produk lain yang berkenaan seperti yang disenaraikan dalam Notis Privasi di lampiran sebelah, melalui saluran-saluran berikut; atau',
        form192: ' dan jenama-jenama lain yang dimiliki oleh syarikat induknya, the Estee Lauder Companies, termasuk mana-mana jenama yang diperoleh atau diperkenalkan pada masa hadapan, serta produk-produk lain yang berkenaan seperti yang disenaraikan dalam Notis Privasi di lampiran sebelah, melalui saluran-saluran berikut: ',
        brandName1: 'JO MALONE LONDON',
        rulerFirstname: 'Sila masukkan nama pertama anda',
        rulerLastname: 'Sila masukkan nama keluarga anda',
        rulerEmail: 'Sila masukkan alamat emel yang betul',
        rulerMobile: 'Sila masukkan no. telefon bimbit anda',
        rulerBirth: 'Sila pilih tarikh lahir anda',
        alreadyRP: 'Nombor telefon bimbit ini telah didaftarkan. Sila hubungi Penasihat Kedai untuk mendapatkan bantuan.',
        alreadyRE: 'Alamat e-mel ini telah didaftarkan. Sila hubungi Penasihat Kedai untuk mendapatkan bantuan.',
        newSub1: "Ya! Dengan melengkapkan dan menyerahkan maklumat saya bersama borang pendaftaran ini, saya bersetuju untuk menerima berita, tawaran dan kolateral pemasaran yang lain daripada, dan dihubungi untuk tinjauan oleh, Estee Lauder Malaysia Sdn. Bhd. (entiti sekutu ",
        newSub2: "), melalui emel, mesej teks, panggilan telefon atau mel pos, mengenai sebarang jenama yang dimiliki oleh syarikat induk kami, Estée Lauder Companies Inc., termasuk mana-mana jenama yang diperoleh atau dilancarkan pada masa hadapan, dan produk mereka daripada jenis yang sama seperti yang disenaraikan di dalam Dasar Privasi. ",
        ms: "Cik",
        mrs: "Puan",
        mr: "Encik",
        dr: "Dr",
        othe: "Lain-lain",
        languageCode: 'Bahasa Pilihan',
        mobileAlert: 'Sila isi nombor telefon bimbit tanpa kod Kawasan “0", contoh 12 – 1234 567',
        otherMobileAlert: 'Sila isi nombor telefon lain tanpa kod Kawasan "0‘, contoh 3 – 1234 5678'
    },
    linkConsumer: {
        secondTitle: "Membership added",
        thank: "Thank you, your membership has been successfully added.",
        done: "Done"
    },
    barcode: {
        infoMY: 'Selamat datang ke {0}. Sila kemukakan kod bar ini di Kaunter / Kedai.'
    }
}
